#root {
  overflow: hidden;
}

.App {
  text-align: center;
}

html, body, .App, #root {
  height: 100%;
}

h1 {
  font-family: SegoeUI, sans-serif;
  font-size: 26px;
  font-weight: normal;
  color: #707070;
}

h2 {
  font-family: SegoeUI, sans-serif;
  font-weight: normal;
}

.App-link {
  color: #09d3ac;
}
